import React from 'react';
import Card from './Card.jsx';
import cards from '../Resources/cards.js';
import divider from '../assets/icons/divider1flip.png';
import banner from '../assets/banner1.jpg';
import icon from '../assets/icon3.png';

const Header = () =>
  <div id="header">
    <img
      alt=""
      src={banner}
    >
    </img>
    <div id="logo">
      <img
        alt=""
        src={icon}
      >
      </img>
      <h1>Josh Wentworth</h1>
    </div>
    <div>
      <div className="sectionLabel">
        <h1>What I do</h1>
        <img
          src={divider}
          alt=""
        />
      </div>
      <Card card={cards[0]} />
      <Card card={cards[1]} />
      <Card card={cards[2]} />
    </div>
  </div>;

export default Header;
