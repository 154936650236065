import React from 'react';
import Header from './header.jsx';

const App = () => {
  return (<div>
    <div id="BG" />
    <Header />
  </div>);
};

export default App;
