import React from 'react';

const Card = props =>
  <div className="Card">
    <h2>{props.card.title}</h2>
    <img alt="" src={props.card.pic}></img>
    <h3>{props.card.copy}</h3>
  </div>;

export default Card;
