import software from '../assets/software.png';
import hardware from '../assets/hardware.png';
import consult from '../assets/consult.png';

const cards = [
  {
    title: 'Development',
    pic: software,
    copy: 'Deployable software solutions.',
  },
  {
    title: 'Hardware',
    pic: hardware,
    copy: 'Integration tailored to project needs.',
  },
  {
    title: 'Consulting',
    pic: consult,
    copy: 'Technical solutions for any situation.',
  },
];

export default cards;
